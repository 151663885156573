import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const BlogCardContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.blog-card-container {
    display: flex;
    flex-direction: column;
    background-color: ${({ theme: { palette } }: ThemeType) =>
      palette.p_white.full};
    box-shadow: 0px 4px 40px rgba(68, 67, 67, 0.13);
    & > .blog-image {
      aspect-ratio: 1/1;
    }
    & > .card-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(1.5)};
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(2, 3)};
      & > h1 {
        font-family: "utopia-std-display";
        font-weight: normal;
        font-size: clamp(16px, 1.8vw, 24px);
        line-height: 137.2%;
        color: ${({ theme: { palette } }: ThemeType) => palette.p_black.text};
      }
      & > .blog-date {
        font-family: "utopia-std-display";
        font-weight: normal;
        font-size: 14px;
      }
      & > .read-story {
        position: relative;
        & > .read-story-text {
          font-weight: normal;
          font-size: 14px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: ${({ theme: { palette } }: ThemeType) =>
            palette.p_white.full};
          width: 0;
          opacity: 0;
          display: flex;
          justify-content: center;
          transition: width 200ms ease-in, opacity 150ms ease-in;
          & > p {
            width: max-content;
            flex-shrink: 0;
            opacity: 0;
          }
        }
        & > svg {
          width: 100%;
          fill: ${({ theme: { palette } }: ThemeType) =>
            palette.p_grey.background};
        }
      }
    }
    &:hover {
      cursor: pointer;
      & > .card-content {
        & > .read-story {
          & > svg {
            fill: ${({ theme: { palette } }: ThemeType) =>
              palette.primary.main};
            transition: fill 150ms ease-in;
          }
          & > .read-story-text {
            width: 55px;
            opacity: 1;
            & > p {
              opacity: 1;
              transition: opacity 150ms ease-in;
              transition-delay: 150ms;
            }
          }
        }
      }
    }
  }
`;

import * as React from "react";
import Blogs from "../components/pages/blog";

interface BlogsProps {}

const BlogsPage: React.FC<BlogsProps> = () => {
  return <Blogs />;
};

export default BlogsPage;

import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const TopContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box component="section" {...props} />
))`
  &.blog-top-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${({ theme: { spacing } }: ThemeType) => spacing(6)};
    padding: ${({ theme: { spacing } }: ThemeType) => spacing(12, 0)};

    & > .header-container {
      text-align: center;
      font-family: "utopia-std-display";
      display: flex;
      flex-direction: column;
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(4)};

      & > h1 {
        font-weight: normal;
        font-size: 80px;
      }
      & > h3 {
        font-weight: normal;
        font-size: 24px;
        color: ${({ theme: { palette } }: ThemeType) => palette.p_black.text};
      }
    }
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(15, 0, 5, 0)};

      & > .header-container {
        & > h1 {
          font-size: 64px;
          line-height: 59px;
        }
        & > h3 {
          font-size: 18px;
        }
      }
    }
  }
`;

export const BlogListContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.blog-list-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: min-content;
    gap: ${({ theme: { spacing } }: ThemeType) => spacing(6, 5)};
    min-height: 100vh;
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      grid-template-columns: 1fr;
    }
  }
`;

import * as React from "react";
import { BlogTypesContainer } from "../styles";
import { BlogTypesProps, TypeProps, BlogTypeType } from "../types";
import { graphql, useStaticQuery } from "gatsby";
import { Button } from "@mui/material";

const BlogType: React.FC<TypeProps> = ({
  name,
  setBlogFilterByType,
  blogFilterByType,
}) => {
  return (
    <Button
      onClick={() => setBlogFilterByType(name)}
      className={`single-blog-type ${
        blogFilterByType === name ? "active-type" : ""
      }`}
    >
      {name}
    </Button>
  );
};

const BlogTypes: React.FC<BlogTypesProps> = ({
  setBlogFilterByType,
  blogFilterByType,
}) => {
  const data = useStaticQuery(graphql`
    query {
      pageData: allPrismicBlogType {
        nodes {
          data {
            blog_type
          }
        }
      }
    }
  `);
  const document: BlogTypeType[] = data.pageData.nodes;
  return (
    <BlogTypesContainer className="blog-types-container">
      {document
        .sort(function (x) {
          return x.data.blog_type == "All" ? -1 : 0;
        })
        .map((type, index: number) => {
          return (
            <BlogType
              blogFilterByType={blogFilterByType}
              setBlogFilterByType={setBlogFilterByType}
              key={index}
              name={type.data.blog_type}
            />
          );
        })}
    </BlogTypesContainer>
  );
};

export default BlogTypes;

import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const BlogPageContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box component="div" {...props} />
))`
  &.blogs-container {
    background-color: ${({ theme: { palette } }: ThemeType) =>
      palette.p_white.full};
    & > .blogs-container-max-width {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      max-width: 1440px;
      min-height: 100vh;

      padding: ${({ theme: { spacing } }: ThemeType) => spacing(12, 10)};

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        padding: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
      }
    }
  }
`;

import React from "react";
import { Box } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "components/common/link";
import Arrow from "assets/svg/arrow.svg";
import { BlogCardContainer } from "../styles";
import { BlogCardProps } from "../types";
import { format } from "date-fns";
import { PrismicRichText } from "@prismicio/react";

const BlogCard: React.FC<BlogCardProps> = ({
  title,
  image,
  url,
  date,
  alt,
}) => {
  const formattedDate = format(new Date(date), "MMM dd, yyyy");
  return (
    <Link to={url}>
      <BlogCardContainer className="blog-card-container">
        <GatsbyImage
          className="blog-image"
          image={image}
          alt={alt ?? "blog image"}
        />
        <Box className="card-content">
          <Box className="blog-date">{formattedDate.toString()}</Box>
          <PrismicRichText field={title} />
          <Box className="read-story">
            <Box className="read-story-text">
              <p>Read</p>
            </Box>
            <Arrow />
          </Box>
        </Box>
      </BlogCardContainer>
    </Link>
  );
};

export default BlogCard;

import * as React from "react";
import { Box } from "@mui/material";
import Newsletter from "./components/newsletter";
import BlogTypes from "./components/blog-types";
import { TopContainer } from "../styles";
import { TopProps } from "../types";
import { PrismicRichText } from "@prismicio/react";

const Top: React.FC<TopProps> = ({
  title,
  secondaryTitle,
  setBlogFilterByType,
  blogFilterByType,
}) => {
  return (
    <TopContainer className="blog-top-container">
      <Box className="header-container">
        <PrismicRichText field={title} />
        <PrismicRichText field={secondaryTitle} />
      </Box>
      <Newsletter location="blog" />
      <BlogTypes
        blogFilterByType={blogFilterByType}
        setBlogFilterByType={setBlogFilterByType}
      />
    </TopContainer>
  );
};

export default Top;

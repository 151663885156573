import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "components/common/seo";
import Top from "./components/top";
import BlogList from "./components/blog-list";
import { BlogPageContainer } from "./styles";
import { BlogsProps } from "./types";
import { Box } from "@mui/material";

const Blogs: React.FC<BlogsProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: allPrismicBlogPage {
        nodes {
          data {
            title {
              richText
            }
            secondary_title {
              richText
            }
          }
        }
      }
    }
  `);
  const document = data.pageData.nodes[0].data;
  const [blogFilterByType, setBlogFilterByType] = useState("All");
  return (
    <>
      <Seo />

      <BlogPageContainer className="blogs-container">
        <Box className="blogs-container-max-width">
          <Top
            setBlogFilterByType={setBlogFilterByType}
            blogFilterByType={blogFilterByType}
            title={document.title.richText}
            secondaryTitle={document.secondary_title.richText}
          />
          <BlogList blogFilterByType={blogFilterByType} />
        </Box>
      </BlogPageContainer>
    </>
  );
};

export default Blogs;

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BlogCard from "./components/blog-card";
import { BlogListContainer } from "../styles";
import { BlogListProps, BlogType } from "../types";

const BlogList: React.FC<BlogListProps> = ({ blogFilterByType }) => {
  const data = useStaticQuery(graphql`
    query {
      pageData: allPrismicBlog(
        sort: { fields: data___release_date, order: DESC }
      ) {
        nodes {
          data {
            title {
              richText
            }
            release_date
            blog_post_image {
              gatsbyImageData
              alt
            }
            type {
              slug
            }
          }
          uid
        }
      }
    }
  `);
  const document: BlogType[] = data.pageData.nodes;
  const specialCharacters = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;

  return (
    <BlogListContainer className="blog-list-container">
      {document
        .filter((blog) =>
          blogFilterByType === "All"
            ? blog
            : blogFilterByType
                .toLowerCase()
                .replace(specialCharacters, "")
                .replaceAll(" ", "-") === blog.data.type.slug
        )
        .map((blog, index: number) => {
          return (
            <BlogCard
              key={index}
              title={blog.data.title.richText}
              url={`${blog.data.type.slug}/${blog.uid}/`}
              date={blog.data.release_date}
              alt={blog.data.blog_post_image.alt}
              image={blog.data.blog_post_image.gatsbyImageData}
            />
          );
        })}
    </BlogListContainer>
  );
};

export default BlogList;
